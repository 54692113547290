import { Competency } from '../schemas/Competency';

export const mergeCompetencies = (existing: Competency[], newItems: Competency[]) => {
    const result: Competency[] = [
        ...existing.map((c) => ({
            ...c,
            editTitle: !c.templateId,
            editDescription: !c.templateId,
            editCategory: !c.templateId,
        })),
    ];
    for (const templatedCompetency of newItems) {
        const competency = result.find((competency) => competency.id === templatedCompetency.id);
        const autoApprove = !templatedCompetency.editTitle && !templatedCompetency.editDescription && !templatedCompetency.editCategory;
        if (!competency) {
            result.push({
                ...templatedCompetency,
                ...(autoApprove ? { managerApproved: 'Approved', hrApproved: 'Approved' } : undefined),
            });
            continue;
        }
        competency.templateId = templatedCompetency.templateId;
        competency.sortOrder = templatedCompetency.sortOrder;
        competency.editTitle = templatedCompetency.editTitle;
        competency.editDescription = templatedCompetency.editDescription;
        competency.editCategory = templatedCompetency.editCategory;
        if (!templatedCompetency.editTitle) {
            competency.title = templatedCompetency.title;
        }
        if (!templatedCompetency.editDescription) {
            competency.description = templatedCompetency.description;
        }
        if (!templatedCompetency.editCategory) {
            competency.competencyCategory = templatedCompetency.competencyCategory;
        }
        if (autoApprove) {
            competency.managerApproved = 'Approved';
            competency.hrApproved = 'Approved';
        }
    }
    return result;
};
