import { EmployeeLevel } from '@jucy-askja/common/schemas/employeeLevel';
import { getEmployeeLevelLabel } from './getEmployeeLevelLabel';

export const formatLevels = (data?: EmployeeLevel[]) =>
    data
        ?.map((d) => ({
            id: d.id,
            name: getEmployeeLevelLabel(data, d.id),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
