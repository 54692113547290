import { autoId } from '@jucy-askja/common/lib/autoId';
import { stripHtml } from '@jucy-askja/common/lib/stripHtml';
import { Template } from '@jucy-askja/common/schemas/Template';
import { ResourceCallbacks } from 'ra-core';

export const templateBeforeSave: ResourceCallbacks[] = [
    {
        resource: 'template',
        beforeSave: async (template: Template) => {
            const competencies = template?.competencies || [];
            let count = 0;
            for (const competency of competencies) {
                competency.sortOrder = count;
                if (!competency.id) {
                    competency.id = autoId();
                }
                const description = stripHtml(competency.description || '').trim();
                if (!description) {
                    competency.description = null;
                }
                count++;
            }
            return template;
        },
    },
];
