import { useQuery } from '@tanstack/react-query';
import { useGetIdentity } from 'react-admin';
import { getUserProfile } from '#/services/getUserProfile';

export const useGetUserProfile = () => {
    const { data: identity, isLoading: identityLoading } = useGetIdentity();
    return useQuery({
        queryKey: ['user-profile', identity?.id],
        queryFn: () => getUserProfile(),
        enabled: Boolean(!identityLoading && identity?.id),
        staleTime: Infinity,
    });
};
