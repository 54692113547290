import * as React from 'react';
import { useGetUserProfile } from '#/hooks/useGetUserProfile';
import { Box, Typography } from '@mui/material';

const ReviewList = React.lazy(() => import('#/resources/Review/ReviewList'));

const Dashboard = () => {
    const { data: profile } = useGetUserProfile();
    return (
        <>
            <Box sx={{ marginTop: 10, marginX: 20 }}>
                <Typography variant="h4">Your KPIs</Typography>
                <ReviewList resource="selfReview" reviewType="selfReview" />
            </Box>
            {profile?.role === 'manager' ? null : (
                <Box sx={{ marginTop: 10, marginX: 20, mb: 5 }}>
                    <Typography variant="h4">Your Employee KPIs</Typography>
                    <ReviewList resource="employeeReview" reviewType="employeeReview" />
                </Box>
            )}
        </>
    );
};

export default Dashboard;
