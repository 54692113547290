import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from '#/lib/appearance';
import { ThemeProvider } from '@mui/material';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

export const Providers = ({ children }: { children: React.ReactNode }) => (
    <ThemeProvider theme={theme}>
        {children}
        <ToastContainer />
    </ThemeProvider>
);
