import './lib/init';
import React from 'react';
import { Resource, useRecordContext } from 'react-admin';
import { useGetFormattedTemplates } from '#/hooks/useGetFormattedTemplates';
import { Template } from '@jucy-askja/common/schemas/Template';
import { Admin } from './components/Admin';
import { Providers } from './components/Providers';
import * as competencyCategory from './resources/CompetencyCategory';
import * as employeeLevel from './resources/EmployeeLevel';
import * as employeeReview from './resources/EmployeeReview';
import * as employee from './resources/Employees';
import * as hrReview from './resources/HrReview';
import * as review from './resources/Review';
import * as reviewPeriod from './resources/ReviewPeriod';
import * as selfReview from './resources/SelfReview';
import * as template from './resources/Templates';

const TemplateRepresentation = () => {
    const template = useRecordContext<Template>();
    const templates = useGetFormattedTemplates() || [];
    return templates.find((t) => template && t.id === template.id)?.name || 'Unknown';
};

export const App = () => (
    <Providers>
        <Admin>
            <Resource name="selfReview" options={{ label: 'KPI Review' }} list={selfReview.List} show={review.Show} edit={selfReview.Edit} />
            <Resource name="employeeReview" options={{ label: 'Employee Reviews' }} list={employeeReview.List} show={review.Show} edit={employeeReview.Edit} />
            <Resource name="hrReview" options={{ label: 'HR Reviews' }} list={hrReview.List} show={review.Show} edit={hrReview.Edit} />
            <Resource name="editReview" options={{ label: 'Edit Reviews' }} list={review.List} show={review.Show} edit={review.Edit} create={review.Edit} />
            <Resource name="reviewPeriod" options={{ label: 'Review Periods' }} list={reviewPeriod.List} show={reviewPeriod.Show} create={reviewPeriod.Create} edit={reviewPeriod.Edit} />
            <Resource
                name="template"
                recordRepresentation={<TemplateRepresentation />}
                options={{ label: 'Templates' }}
                list={template.List}
                show={template.Show}
                create={template.Create}
                edit={template.Edit}
            />
            <Resource
                name="employee"
                options={{ label: 'Employees' }}
                list={employee.List}
                show={employee.Show}
                create={employee.Create}
                edit={employee.Edit}
                recordRepresentation={(record) => `${record.name || ''}`}
            />
            <Resource
                name="employeeLevel"
                options={{ label: 'Employee Levels' }}
                list={employeeLevel.List}
                show={employeeLevel.Show}
                create={employeeLevel.Create}
                edit={employeeLevel.Edit}
                recordRepresentation={(record) => `${record.name || ''}`}
            />
            <Resource
                name="competencyCategory"
                options={{ label: 'Competency Categories' }}
                list={competencyCategory.List}
                show={competencyCategory.Show}
                create={competencyCategory.Create}
                edit={competencyCategory.Edit}
                recordRepresentation={(record) => `${record.name || ''}`}
            />
        </Admin>
    </Providers>
);
