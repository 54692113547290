import { app } from '#/lib/init';
import { RAFirebaseOptions } from '#/lib/react-admin-firebase';

export const options: RAFirebaseOptions = {
    app: app,
    lazyLoading: {
        enabled: false,
    },
    metaFieldCasing: 'camel',
    renameMetaFields: {
        created_at: 'createdAt',
        created_by: 'createdBy',
        updated_at: 'updatedAt',
        updated_by: 'updatedBy',
    },
};
