import React from 'react';
import { useGetIdentity } from 'react-admin';
import { Navigate } from 'react-router-dom';
import { useGetUserProfile } from '#/hooks/useGetUserProfile';
import { Box, CircularProgress } from '@mui/material';
import { ErrorComponent } from './ErrorComponent';

export const ProfileLoader = ({ children }: { children?: React.ReactNode }) => {
    const { data: identity, isLoading: identityLoading, error: identityError } = useGetIdentity();
    const { data: profile, isLoading: isProfileLoad, error: profileError } = useGetUserProfile();

    if (identityLoading || isProfileLoad) {
        return (
            <Box display="flex" justifyContent="center" padding={2} width="100%">
                <CircularProgress />
            </Box>
        );
    }
    if (identityError) {
        return <ErrorComponent error={identityError} />;
    }
    if (!identity) {
        return <Navigate to="/login" />;
    }
    if (profileError) {
        return <ErrorComponent error={profileError} />;
    }
    if (!profile) {
        return <>Employee not found</>;
    }
    return children;
};
