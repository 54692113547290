import React, { ReactElement, useMemo } from 'react';
import { Menu } from 'react-admin';
import { useGetUserProfile } from '#/hooks/useGetUserProfile';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CategoryIcon from '@mui/icons-material/Category';
import EditIcon from '@mui/icons-material/Edit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PeopleIcon from '@mui/icons-material/People';
import ReviewsIcon from '@mui/icons-material/Reviews';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';

export type MenuItem = {
    to: string;
    primaryText: string;
    leftIcon: ReactElement;
    roles?: string[];
};
type MenuSection = {
    title: string;
    items: MenuItem[];
};

const menuItems: Record<string, MenuSection> = {
    primary: {
        title: 'Reviews',
        items: [
            { to: '/', primaryText: 'Your KPIs', leftIcon: <ReviewsIcon /> },
            { to: '/hrReview', primaryText: 'HR Reviews', leftIcon: <ReviewsIcon />, roles: ['admin'] },
        ],
    },
    admin: {
        title: 'Admin',
        items: [
            { to: '/editReview', primaryText: 'Edit Reviews', leftIcon: <EditIcon />, roles: ['admin'] },
            { to: '/reviewPeriod', primaryText: 'Review Periods', leftIcon: <EditCalendarIcon />, roles: ['admin'] },
            { to: '/template', primaryText: 'Templates', leftIcon: <CalendarViewMonthIcon />, roles: ['admin'] },
            { to: '/employee', primaryText: 'Employees', leftIcon: <PeopleIcon />, roles: ['admin'] },
            { to: '/employeeLevel', primaryText: 'Employee Levels', leftIcon: <StarIcon />, roles: ['admin'] },
            { to: '/competencyCategory', primaryText: 'KPI Categories', leftIcon: <CategoryIcon />, roles: ['admin'] },
        ],
    },
    manager: {
        title: 'Manager',
        items: [{ to: '/employee', primaryText: 'Employees', leftIcon: <PeopleIcon />, roles: ['manager'] }],
    },
};

const useMenuItems = (role?: string) =>
    useMemo(() => {
        const items: MenuSection[] = [];
        for (const key in menuItems) {
            if (menuItems[key].items) {
                const section = {
                    ...menuItems[key],
                    items: menuItems[key].items.filter((item: MenuItem) => {
                        if (item.roles) {
                            return role ? item.roles.includes(role) : false;
                        }
                        return true;
                    }),
                };
                if (section.items.length) {
                    items.push(section);
                }
            }
        }
        return items;
    }, [role]);

export const CustomMenu = () => {
    const { data: user } = useGetUserProfile();
    const menuItems = useMenuItems(user?.role);
    return (
        <Menu>
            {menuItems.map((section) => (
                <Box key={`${section.title}`}>
                    <Typography variant="h6" color="textSecondary" sx={{ paddingLeft: 2 }}>
                        {section.title}
                    </Typography>
                    {section.items.map((item) => (
                        <Menu.Item key={`${item.to}${item.primaryText}`} to={item.to} primaryText={item.primaryText} leftIcon={item.leftIcon} />
                    ))}
                </Box>
            ))}
        </Menu>
    );
};
