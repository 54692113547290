export type EmulatorConfig = {
    host: string;
    port: number;
};
export type Config = {
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
        emulator?: {
            auth?: EmulatorConfig;
            functions?: EmulatorConfig;
            firestore?: EmulatorConfig;
            storage?: EmulatorConfig;
            database?: EmulatorConfig;
        };
    };
    msConfig: {
        tenantId: string;
        clientId: string;
    };
};

const getEnv = () => {
    const dev = ['dev', 'development'];
    if (typeof process !== 'undefined') {
        return dev.includes(process.env.ENV || process.env.NODE_ENV || 'development') ? 'dev' : 'prod';
    }
    if (import.meta.env?.MODE) {
        return dev.includes(import.meta.env.MODE) ? 'dev' : 'prod';
    }
    if (import.meta.env?.ENV) {
        return dev.includes(import.meta.env.ENV) ? 'dev' : 'prod';
    }
    return 'dev';
};

const isDev = getEnv() === 'dev';

export const config: Config = {
    firebaseConfig: {
        apiKey: 'AIzaSyAB2sOi8FJlfznjci4kwD5mt0kdWfPY6pY',
        authDomain: 'r2e.jucy.com',
        projectId: 'jucy-askja',
        storageBucket: 'jucy-askja.appspot.com',
        messagingSenderId: '932290405279',
        appId: '1:932290405279:web:b1353abcc8fe621ea753aa',
        ...(isDev
            ? {
                  emulator: {
                      auth: { host: 'localhost', port: 9099 },
                      functions: { host: 'localhost', port: 5001 },
                      firestore: { host: 'localhost', port: 8080 },
                      storage: { host: 'localhost', port: 9199 },
                      database: { host: 'localhost', port: 9000 },
                  },
              }
            : undefined),
    },
    msConfig: {
        tenantId: '388a7ead-88d2-4311-aaca-8af448624ddd',
        clientId: '68971ec6-8bba-4a15-9acd-007c8c2ad929',
    },
};
