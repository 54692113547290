import { EmployeeLevel } from '@jucy-askja/common/schemas/employeeLevel';

export const getEmployeeLevelLabel = (employeeLevels: EmployeeLevel[], employeeLevelId: string): string => {
    const employeeLevel = employeeLevels.find((level) => level.id === employeeLevelId);
    const name = employeeLevel?.name || '';
    if (!employeeLevel) {
        return name;
    }
    const parentLabel = employeeLevel.parentId && employeeLevel.parentId !== employeeLevel.id ? getEmployeeLevelLabel(employeeLevels, employeeLevel.parentId) : null;
    if (parentLabel && name) {
        return `${parentLabel} - ${name}`;
    }
    return name;
};
