import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { Navigate } from 'react-router-dom';
import useWaitForIdentity from '#/lib/useWaitForIdentity';
import { config } from '@jucy-askja/common/config';
import { Alert, AlertTitle, Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { LoginComponent, useLogin } from 'ra-core';
import { LoginProps } from 'ra-ui-materialui';
import { OAuthProvider, getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, signInWithPopup } from 'firebase/auth';
import 'firebase/compat/auth';
import Container from './authentification/Container';
import SignUpForm from './authentification/SignUpForm';

const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
    tenant: config.msConfig.tenantId,
});
const CustomLoginPage: React.FC<LoginProps> = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [signUpEmail, setSignUpEmail] = React.useState('');
    const [showSignUp, setShowSignUp] = useState(false);

    const login = useLogin();
    const auth = getAuth();

    const handleShowSignUpForm = () => {
        setShowSignUp(true);
    };
    const handleSignUpRequest = (email: string) => {
        setShowSignUp(false);

        const actionCodeSettings = {
            url: window.origin,
            handleCodeInApp: true,
        };

        const auth = getAuth();

        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);
            });
    };

    if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
            email = window.prompt('Please provide your email for confirmation');
            setSignUpEmail(email as string);
        }
        if (email !== null) {
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');

                    login(result.user).catch((error) => notify(error));
                })
                .catch((error) => notify(error));
        } else {
            notify('No email provided');
        }
    }

    const waitForIdentity = useWaitForIdentity();

    const handleStaffLogin = () => {
        setLoading(true);
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential?.accessToken;
                const idToken = credential?.idToken;
                setLoginSuccess(Boolean(accessToken && idToken));
                if (!accessToken || !idToken) {
                    notify('Invalid email or password');
                    return;
                }
                waitForIdentity().then(() => {
                    setLoading(false);
                });
            })
            .catch((error) => {
                setLoading(false);
                notify('Invalid email or password');
                setError(error);
            });
    };

    if (error) {
        return (
            <Container>
                <Box paddingBottom={2}>
                    <Alert severity="error">
                        <AlertTitle>Authentication failure</AlertTitle>
                        There was an error while trying to authenticate:
                        <br />
                        <br />
                        <em>{'Unknown error'}</em>
                    </Alert>
                </Box>
            </Container>
        );
    }

    if (loading) {
        return (
            <Container>
                <CircularProgress className="animated fadeIn" sx={{ margin: '20px' }} />
            </Container>
        );
    }

    if (loginSuccess) {
        return <Navigate to={'/'} />;
    }
    return (
        <Container>
            <Typography variant="h6">Welcome!</Typography>
            Log in with
            <Stack sx={{ alignItems: 'center' }}>
                <Button onClick={handleStaffLogin} variant="contained" sx={{ margin: 2, width: '160px' }}>
                    Work email
                </Button>
                <Divider flexItem />
                or
                <Divider flexItem />
                {showSignUp ? (
                    <SignUpForm setSignUpEmail={setSignUpEmail} signUpEmail={signUpEmail} handleSignUpRequest={handleSignUpRequest} />
                ) : (
                    <Button onClick={handleShowSignUpForm} variant="contained" sx={{ margin: 2, width: '160px' }}>
                        Personal email
                    </Button>
                )}
            </Stack>
        </Container>
    );
};

export default CustomLoginPage as LoginComponent;
