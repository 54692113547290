import { useGetList } from 'react-admin';
import { Template } from '@jucy-askja/common/schemas/Template';
import { useGetFormattedEmployeeLevels } from './useGetFormattedEmployeeLevels';

export const useGetFormattedTemplates = () => {
    const { data: templates } = useGetList<Template>('template');
    const levels = useGetFormattedEmployeeLevels();
    return templates?.map((template) => {
        const level = template && levels ? levels.find((level) => level.id === template.employeeLevel) : undefined;
        return {
            id: template.id,
            name: level?.name || 'unknown',
            template,
        };
    });
};
