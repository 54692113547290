import { stripHtml } from '@jucy-askja/common/lib/stripHtml';
import { Review } from '@jucy-askja/common/schemas/Review';
import { ResourceCallbacks, ResourceCallbacksValue } from 'ra-core';

const reviewBeforeSaveFn: ResourceCallbacksValue<Review> = async (review: Review) => {
    for (const competency of review.competencies) {
        const description = stripHtml(competency.description || '').trim();
        if (!description) {
            competency.description = null;
        }
        const managerComment = stripHtml(competency.managerComment || '').trim();
        if (!managerComment) {
            competency.managerComment = null;
        }
        const hrComment = stripHtml(competency.hrComment || '').trim();
        if (!hrComment) {
            competency.hrComment = null;
        }
    }
    return review;
};

export const reviewBeforeSave: ResourceCallbacks[] = [
    { resource: 'selfReview', afterRead: reviewBeforeSaveFn },
    { resource: 'employeeReview', afterRead: reviewBeforeSaveFn },
    { resource: 'hrReview', afterRead: reviewBeforeSaveFn },
    { resource: 'review', afterRead: reviewBeforeSaveFn },
];
