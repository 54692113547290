import { FirebaseDataProvider } from '#/lib/react-admin-firebase';
import { withLifecycleCallbacks } from 'react-admin';
import { config } from '@jucy-askja/common/config';
import { options } from '../providerOptions';
import { reviewAfterRead } from './callbacks/reviewAfterRead';
import { reviewBeforeSave } from './callbacks/reviewBeforeSave';
import { templateAfterRead } from './callbacks/templateAfterRead';
import { templateBeforeSave } from './callbacks/templateBeforeSave';

export const dataProvider = withLifecycleCallbacks(FirebaseDataProvider(config.firebaseConfig, options), [
    ...reviewAfterRead,
    ...reviewBeforeSave,
    ...templateBeforeSave,
    ...templateAfterRead,
]);
