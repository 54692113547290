import { Review } from '@jucy-askja/common/schemas/Review';
import { ResourceCallbacks } from 'ra-core';

export const templateAfterRead: ResourceCallbacks[] = [
    {
        resource: 'template',
        afterRead: async (review: Review) => {
            review.competencies = review.competencies?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0)) || [];
            return review;
        },
    },
];
