import React from 'react';
import { Admin as RaAdmin } from 'react-admin';
import 'react-toastify/dist/ReactToastify.css';
import { authProvider, dataProvider } from '#/services/ra-providers';
import { customTheme, palette } from '#/themes/customTheme';
import { Layout, LayoutProps } from 'ra-ui-materialui';
import CustomLoginPage from './CustomLoginPage';
import Dashboard from './Dashboard';
import { ProfileLoader } from './ProfileLoader';
import { CustomAppBar } from './layout/CustomAppBar';
import { CustomMenu } from './layout/CustomMenu';

const CustomLayout = ({ children, ...props }: LayoutProps) => (
    <Layout
        {...props}
        sx={{
            backgroundImage: `linear-gradient(0deg, ${palette.secondary.main}, ${palette.secondary.shadows[0]} 99%)`,
            '& .RaLayout-content': {
                backgroundColor: 'transparent',
            },
            '& .RaAppBar-menuButton': {
                display: 'none',
            },
        }}
        menu={CustomMenu}
        appBar={CustomAppBar}
    >
        <ProfileLoader>{children}</ProfileLoader>
    </Layout>
);

export const Admin = ({ children }: { children: React.ReactNode }) => {
    document.title = 'Road2Excellence';
    return (
        <RaAdmin dashboard={Dashboard} loginPage={CustomLoginPage} theme={customTheme} layout={CustomLayout} dataProvider={dataProvider} authProvider={authProvider} requireAuth={true}>
            {children}
        </RaAdmin>
    );
};
